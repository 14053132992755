import React from 'react';
import { useAuth } from '@src/auth';
import { UserRoles } from '@spider:src/enums';
import { AdminPoints } from './components/AdminPoints';
import { UserPoints } from './components/UserPoints';

const Points = () => {
  const { hierarchyNodeUser } = useAuth();

  return (
    <>
      {hierarchyNodeUser.role === UserRoles.ADMINISTRATOR && <AdminPoints />}
      {hierarchyNodeUser.role === UserRoles.COLLABORATOR && <UserPoints />}
    </>
  );
};

export default Points;
